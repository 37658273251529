<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>市场中心</a-breadcrumb-item>
            <a-breadcrumb-item>表单编辑</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-nav surveyEdit">
            <div style='width:300px'>
                <div style='margin-bottom:20px'>字段库</div>
            </div>
            <div class='surveyEdit-menu' style='flex:1;border-bottom:1px solid #d9d9d9'>
                <div style='margin-bottom:20px;text-align:right;position:relative;z-index:20'>
                    <a-button @click="toSave" :loading="loading" type="primary" icon="save">保存</a-button>
                    <a-button @click="back" style="margin:0 10px" type="primary" icon="rollback">返回</a-button>
                </div>
            </div>
            <div  style='width:300px;padding-left:20px;border-bottom:1px solid #d9d9d9'>
                <div style='margin-bottom:20px'>字段属性</div>
            </div>
        </div>
        <div class="main-navF surveyEdit">
            <div style='width:300px;min-height:780px'>
                <a-row :gutter="10">
                    <a-col v-for="(item,index) in btnList" @click='addFormItem(item)' :key='index' :span="11">
                        <div type="dashed" class='surveyEdit-btn'>
                            <svg style="width:12px;height:12px;" :fill="'#000'" aria-hidden="true">
                                <use :xlink:href="item.survey_question_icon"></use>
                            </svg>                                
                            {{item.survey_question_name}}
                        </div>
                    </a-col>
                </a-row>
            </div>
            <div class='surveyEdit-menu' style='flex:1'>
                <a-form @submit='toSave' :form="form" ref="surveyEditMenu">
                    <vue-element-loading :active="upLoading" color="#00cca2" spinner="spinner"/>
                    <a-form-item style="padding-left:15px" v-for="(item, index) of surveyData" :key="index" :label="item.label">
                        <a-input style='width:300px' v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
                        
                        <a-select style='width:300px' allowClear show-search :filter-option="filterOption" v-else-if="item.type === 'select'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
                            <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
                        </a-select>

                        <a-tree-select v-else-if="item.type === 'tags'" style='width:300px'
                            v-decorator="[item.name, { rules: item.rules }]"
                            :tree-data="tags"
                            tree-checkable
                            allowClear
                            treeNodeFilterProp="title"
                            :maxTagCount='1'
                            :maxTagTextLength='5'
                            :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                            :show-checked-strategy="SHOW_ALL"
                            placeholder="请选择标签"
                        />

                        <div v-if="item.type === 'upload'" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
                        <LImg v-if="imageUrl" :src="imageUrl" style="width: 100px; height: 100px;"/>
                        <div v-if="imageUrl" class="close" @click="closeImg($event)">&times;</div>
                        <a-button v-if="!imageUrl" type="dashed" icon="file-image">选择图片</a-button>
                        </div>
                        <l-editor style='width:90%' v-else-if="item.type === 'textarea2'" name="content" :data="editor.content"/>
                    </a-form-item>

                    <a-menu :selectedKeys='selectedKeys'>
                        <draggable v-model="list">
                            <transition-group>
                                    <a-menu-item v-if='it.survey_question_status' :class="selectedKeys[0] == index?'menuColor':''" v-for="(it,index) in list" :key='it.survey_question_type + index'>
                                        <a-form-item >
                                            <span slot="label"><span v-if='it.survey_question_can_skip == 1' class='surveyEdit-icon'>*</span> {{it.survey_question_name}} <span v-if='it.survey_question_descr'>({{it.survey_question_descr}})</span></span>
                                            <div class='surveyEdit-warp'>
                                                <a-input :value="it.defaultValue" v-if="it.survey_question_type == 'text' || it.survey_question_type == 'email' || it.survey_question_type == 'location' || it.survey_question_type == 'map_address' || it.survey_question_type == 'verify_code'" style='width:300px'/>
                                                <a-input v-if="it.survey_question_type == 'mobile'" :suffix="it.verify_code == 1?'发送验证码':''" style='width:300px'/>
                                                <a-input-number v-if="it.survey_question_type == 'number' || it.survey_question_type == 'studio' || it.survey_question_type == 'teacher' || it.survey_question_type == 'course_consultant' || it.survey_question_type == 'signature'" style='width:300px' />
                                                <a-textarea auto-size :value="it.defaultValue" v-if="it.survey_question_type == 'textarea'" style='width:300px' />
                                                <a-select v-if="it.survey_question_type == 'dropdown'" v-model="it.defaultValue" placeholder="请选择" style="width: 300px">
                                                    <a-select-option v-for='(i,ind) in it.options' :key='index + ind' :value="i.value">
                                                        {{i.label}}
                                                    </a-select-option>
                                                </a-select>
                                                <a-radio-group v-model="it.defaultValue" v-if="it.survey_question_type == 'radio' || it.survey_question_type == 'gender'">
                                                    <a-radio v-for='(i,index) in it.options' :key='i.value + index' :value="i.value">
                                                            {{i.label}}
                                                    </a-radio>
                                                </a-radio-group>
                                                <a-date-picker :value='it.defaultValue' v-if="it.survey_question_type == 'date'" style='width:300px' />
                                                <a-date-picker :value='it.defaultValue' show-time v-if="it.survey_question_type == 'datetime'" style='width:300px' />
                                                <a-button v-if="it.survey_question_type == 'image'" ><a-icon type="upload" />请选择文件 </a-button>
                                                <div class='surveyEdit-cover'>
                                                    <span v-if="it.survey_question_type == 'textarea'">0/{{it.max_length}}</span>
                                                </div>
                                            </div>
                                            <a-checkbox-group
                                                v-if="it.survey_question_type == 'checkbox'" 
                                                v-model="it.defaultValue"
                                                name="checkboxgroup"
                                                :options="it.options"
                                            />
                                            <a-icon type="delete" @click="delFormItem(index,it)" style='fontSize:20px;margin-left:20px;position: relative;z-index: 100;' />
                                        </a-form-item>
                                        <div @click="clickMenus(it,index)" class="surveyEdit-click"></div>
                                    </a-menu-item>
                            </transition-group>
                        </draggable>
                    </a-menu>


                    
                </a-form>
            </div>
            <div class="surveyEdit-right">
                <a-form v-if='formData'>
                  <a-form-item label="标识名">
                      <a-input v-model="formData.survey_question_name" style="width: 200px"/>
                      <div>标识名不能为空</div>
                  </a-form-item>
                  <a-form-item label="提示文字">
                      <a-input v-model="formData.survey_question_descr" style="width: 200px" />
                      <div>显示在标识名右侧的说明文字</div>
                  </a-form-item>
                  <a-form-item v-if="formData.survey_question_type == 'checkbox' || formData.survey_question_type == 'dropdown' || formData.survey_question_type == 'radio' || formData.survey_question_type == 'gender'" label="选项设置">
                      <a-checkbox-group v-model="formData.defaultValue" v-if="formData.survey_question_type == 'checkbox'">
                          <a-row>
                              <a-col v-for='(it,index) in formData.options' :key='it.value + index' :span="20">
                                <a-checkbox :value="it.value">
                                   <a-input v-model="it.label" />
                                   <a-icon @click.stop="addCheckBox" style='fontSize:20px;margin-left:5px' type="plus-circle" />
                                   <a-icon @click.stop="delCheckBox(index)" style='fontSize:20px;margin-left:5px' type="minus-circle" />
                                </a-checkbox>
                              </a-col>
                          </a-row>
                      </a-checkbox-group>
                      <a-radio-group v-model="formData.defaultValue" v-if="formData.survey_question_type == 'dropdown' || formData.survey_question_type == 'radio' || formData.survey_question_type == 'gender'">
                        <a-row>
                            <a-col v-for='(it,index) in formData.options' :key='it.value + index' :span="20">
                            <a-radio :value="it.value">
                                <a-input v-model="it.label" />
                                <a-icon @click.stop="addCheckBox" style='fontSize:20px;margin-left:5px' type="plus-circle" />
                                <a-icon @click.stop="delCheckBox(index)" style='fontSize:20px;margin-left:5px' type="minus-circle" />
                            </a-radio>
                            </a-col>
                        </a-row>
                      </a-radio-group>
                  </a-form-item>
                  <a-form-item v-if="formData.survey_question_type == 'textarea'" label="字数上限">
                      <a-input-number v-model="formData.max_length" :min="1" :max="2000" style="width: 200px" />
                      <div>上限为两千字</div>
                  </a-form-item>
                  <a-form-item label="">
                      <div><a-checkbox @change="handleIsNull" :checked="formData.survey_question_can_skip == 1">设为必填</a-checkbox></div>
                  </a-form-item>
                  <!-- <a-form-item label="" v-if="formData.survey_question_type == 'mobile'">
                      <div>开启短信验证</div>
                      <a-switch @change="handleSwitch" :check="formData.verify_code == 1"  />
                  </a-form-item> -->
                </a-form>
            </div>
        </div>
        <ImageModal v-if="imagesVisible"/>
    </div>
</template>

<script>
const btnList = [
    {survey_question_name:'单行文本',survey_question_type:'text',survey_question_icon:'#icon-SchemeOutline'},
    {survey_question_name:'多行文本',survey_question_type:'textarea',survey_question_icon:'#icon-SchemeOutline'},
    {survey_question_name:'下拉框',survey_question_type:'dropdown',survey_question_icon:'#icon-SchemeOutline'},
    {survey_question_name:'多选',survey_question_type:'checkbox',survey_question_icon:'#icon-SchemeOutline'},
    {survey_question_name:'单选',survey_question_type:'radio',survey_question_icon:'#icon-SchemeOutline'},
    {survey_question_name:'性别',survey_question_type:'gender',survey_question_icon:'#icon-SchemeOutline'},
    {survey_question_name:'数字',survey_question_type:'number',survey_question_icon:'#icon-SchemeOutline'},
    {survey_question_name:'校区名称',survey_question_type:'studio',survey_question_icon:'#icon-SchemeOutline'},
    {survey_question_name:'课程顾问',survey_question_type:'course_consultant',survey_question_icon:'#icon-SchemeOutline'},
    {survey_question_name:'授课老师',survey_question_type:'teacher',survey_question_icon:'#icon-SchemeOutline'},
    // {survey_question_name:'地理位置',survey_question_type:'location',survey_question_icon:'#icon-SchemeOutline'},
    {survey_question_name:'手机号码',survey_question_type:'mobile',survey_question_icon:'#icon-SchemeOutline'},
    // {survey_question_name:'手机验证码',survey_question_type:'verify_code',survey_question_icon:'#icon-SchemeOutline'},
    {survey_question_name:'签名',survey_question_type:'signature',survey_question_icon:'#icon-SchemeOutline'},
    {survey_question_name:'邮箱',survey_question_type:'email',survey_question_icon:'#icon-SchemeOutline'},
    {survey_question_name:'日期',survey_question_type:'date',survey_question_icon:'#icon-SchemeOutline'},
    {survey_question_name:'日期时间',survey_question_type:'datetime',survey_question_icon:'#icon-SchemeOutline'},
    {survey_question_name:'图片上传',survey_question_type:'image',survey_question_icon:'#icon-SchemeOutline'},
    {survey_question_name:'省市区',survey_question_type:'map_address',survey_question_icon:'#icon-SchemeOutline'},
]
const surveyData = [
  {
    name: 'survey_image',
    label: '表单头图',
    type: 'upload',
    rules: [{ required: true, message: '请输入通知摘要!' }]
  },
  {
    name: 'survey_name',
    label: '表单名称',
    type: 'input',
    rules: [{ required: true, message: '请输入表单名称!' }],
  },

  {
    name: 'survey_qywx_id',
    label: '企业微信',
    type: 'select',
    rules: [{ required: false}],
    items: {
      data: 'qywx_ids',
      label: 'filter_name',
      value: 'employee_id'
    }
  },
  {
    name: 'survey_tags',
    label: '表单标签',
    type: 'tags',
    rules:[],
  },
  {
    name: 'survey_descr',
    label: '表单描述',
    type: 'textarea2',
    rules: []
  },
]

import ranges from "@/common/mixins/ranges"
import draggable from 'vuedraggable'
import editModal from '../survey/editModal'
import ImageModal from '@/views/modal/images'
    export default {
        name:"surveyEdit",
        mixins: [ranges],
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                btnList,
                surveyData,
                list:[],
                loading:false,
                upLoading:false,
                isEditModal:false,
                imagesVisible:false,
                selectedKeys:['text1'],
                formData:null,
                tags:[],
                survey:[],
                seleteItems: {
                    qywx_ids:[],
                },
                form: this.$form.createForm(this),
                isEdit:this.$route.params.isEdit,
                survey_id:this.$route.params.id,
                content: '',
                editor: {},
                imageUrl: '',
            }
        },
        components:{editModal,draggable,ImageModal},
        created () {
            if(this.$route.params.isEdit == '1' || this.$route.params.isEdit == '2'){
                this.getDetail()
            }
            this.getStaff();
            this.getTag()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getTag(){
                let res = await this.$store.dispatch('searchGetTagsAction', {})
                this.tags = res.data
            },
            async getStaff(val){
                this.loading = true
                let obj = {q:val}
                let res = await this.$store.dispatch('searchQyweEmployeeAction', {params:obj})
                this.seleteItems.qywx_ids = res.data
                this.loading = false
            },
            async getDetail(){
                this.upLoading = true
                let res = await this.$store.dispatch('surveyCenterDetailAction', {data: { survey_id: this.survey_id }})
                this.list = res.data.survey.questions
                this.survey = res.data.survey
                this.editor.content = this.survey.survey_descr
                this.imageUrl = this.survey.survey_image
                this.form.setFieldsValue({survey_name:this.survey.survey_name})
                this.form.setFieldsValue({survey_qywx_id:this.survey.survey_qywx_id})
                this.form.setFieldsValue({survey_tags:this.survey.survey_tags})
                this.isEditModal = true
                this.upLoading = false
            },
            addFormItem(item) {
                // console.log(this.list)
                if(item.survey_question_type === 'signature' || item.survey_question_type === 'location'){
                    for (const value of this.list) {
                        if(value.survey_question_type === item.survey_question_type){
                            this.$message.warning('表单中已有该字段!!')
                            return false
                        }
                    }
                }
                let obj = {
                    survey_question_name:item.survey_question_name,
                    survey_question_type:item.survey_question_type,
                    survey_question_id:'',
                    defaultValue:null,
                    survey_question_descr:'',
                    survey_question_can_skip:0,
                    survey_question_status:1,
                    is_unique:0,
                    max_length:1000,
                    options:[],
                }
                if(item.survey_question_type == 'checkbox'){
                    obj.defaultValue = []
                    obj.options = [{label:'选1',value:'选1'},{label:'选2',value:'选2'},{label:'选3',value:'选3'},]
                }
                if(item.survey_question_type == 'dropdown' || item.survey_question_type == 'radio'){
                    obj.defaultValue = ''
                    obj.options = [{label:'选1',value:'选1'},{label:'选2',value:'选2'},{label:'选3',value:'选3'},]
                }
                if(item.survey_question_type == 'gender'){
                    obj.defaultValue = ''
                    obj.options = [{label:'男',value:'男'},{label:'女',value:'女'}]
                }
                if(item.survey_question_type == 'mobile'){
                    obj.verify_code = 0
                }
                this.list.push(obj)
                this.selectedKeys = [this.list.length -1]
                this.formData = obj
                let num = this.$refs.surveyEditMenu.$el.scrollHeight
                this.$nextTick(() => {
                    this.$refs.surveyEditMenu.$el.scrollTo(0,num);
                })
            },
            delFormItem(index,it){
                let that = this
                this.$confirm({
                    title: `您确定删除${it.survey_question_name}？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        if(it.survey_question_id){
                            it.survey_question_status = 0
                        }else{
                            that.list.splice(index,1)
                        }
                        resolve()
                    }).catch(error => console.log(error))
                    }
                })
            },
            clickMenu({item, key, keyPath}){
                // console.log(item.selectedKeys,key, keyPath)
                // this.selectedKeys = keyPath
                // this.formData = this.list[key]
                // this.selectedKeys = item.selectedKeys
                // let keyStr = item.selectedKeys[0]
                // this.formData = this.list[keyStr]
            },
            clickMenus(item,index){
                console.log(item,index)
                this.selectedKeys = [index]
                this.formData = this.list[index]
            },
            handleIsNull(e){
                if(e.target.checked){
                    this.formData.survey_question_can_skip = 1
                }else{
                    this.formData.survey_question_can_skip = 0
                }
            },
            handleSwitch(e){
                console.log(e)
                if(e){
                    this.formData.verify_code = 1
                }else{
                    this.formData.verify_code = 0
                }
            },
            handleIsUnique(e){
                if(e.target.checked){
                    this.formData.is_unique = 1
                }else{
                    this.formData.is_unique = 0
                }
            },
            addCheckBox(){
                let obj = {
                    label:`选${this.formData.options.length + 1}`,
                    value:`选${this.formData.options.length + 1}`
                }
                this.formData.options.push(obj)
            },
            delCheckBox(index){
                this.formData.options.splice(index,1)
                this.formData.options.forEach((item,index)=>{
                    item.value = `选${index + 1}`
                    // item.label = `选${index + 1}`
                })
            },
            async toSave(){
                const { form } = this
                let surveyData = await form.validateFields()
                this.loading = true
                let arr = this.list
                console.log(arr,'arr')
                arr.forEach((item,index)=>{
                    if(!item.survey_question_name){
                        this.$message.warning(`第${index+1}行的标识名不能为空`)
                        return false
                    }
                    if(item.survey_question_type == 'checkbox'){
                        let newArr = []
                        let setting = []
                        item.options.forEach(it=>{
                            newArr.push(it.label)
                            if(it.survey_answer_id){
                                setting.push({survey_answer_name:it.label,survey_answer_id:it.survey_answer_id})
                            }else{
                                setting.push({survey_answer_name:it.label,survey_answer_id:''})
                            }
                        })
                        if((new Set(newArr)).size != item.options.length){
                            this.$message.warning(`第${index+1}行有重复的多选项`)
                            return false
                        }
                        let default_value = []
                        item.setting = setting
                        item.default_value = default_value
                    }
                    if(item.survey_question_type == 'dropdown' || item.survey_question_type == 'radio' || item.survey_question_type == 'gender'){
                        console.log(2)
                        let newArr = []
                        let setting = []
                        item.options.forEach(it=>{
                            newArr.push(it.label)
                            if(it.survey_answer_id){
                                setting.push({survey_answer_name:it.label,survey_answer_id:it.survey_answer_id})
                            }else{
                                setting.push({survey_answer_name:it.label,survey_answer_id:''})
                            }
                        })
                        if((new Set(newArr)).size != item.options.length){
                            this.$message.warning(`第${index+1}行有重复的选项`)
                            return false
                        }
                        let default_value
                        item.options.forEach(it=>{
                            if(it.value == item.defaultValue){
                                default_value = it.value
                            }
                        })
                        item.setting = setting
                        item.default_value = default_value
                    }
                })
                let params = {
                    survey_question:arr,
                    survey_descr:this.editor.content,
                    survey_image:this.imageUrl,
                    survey_qywx_id:surveyData.survey_qywx_id,
                    survey_tags:surveyData.survey_tags,
                    survey_name:surveyData.survey_name
                }
                if(this.survey_id != '-1'){
                    params.survey_id = this.survey_id
                }
                let res = await this.$store.dispatch(this.isEdit != '1'?'surveyQuestionAddAction':'surveyQuestionUpdateAction', { data: params }).then(res=>{
                    this.$message.success('操作成功')
                    this.$router.go(-1)
                    this.loading = false
                }).catch(err=>{
                    this.loading = false
                })
                
            },
            back(){
                this.$router.go(-1)
            },
            showEditModal(){
                this.isEditModal = true
            },
            hideEditModal(){
                this.isEditModal = false
            },
            showImagesModal(imageKey) {
                this.imageKey = imageKey
                this.imagesVisible = true
            },
            hideImagesModal(image) {
                if (image) {
                    this.imageUrl = image.path
                }
                this.imagesVisible = false
            },
            changeEditor(key, value) {
                this.editor[key] = value
            },
            closeImg(e) {
                e.stopPropagation()
                this.imageUrl = ''
            },
        },
    }
</script>

<style lang="scss">
    .ant-form-item{
        margin-bottom:12px; 
    }
    .main-nav{
        padding: 15px 15px 0px 15px;
        background: #fff;
    }
    .main-navF{
        padding: 0px 15px 15px 15px;
        background: #fff;
    }
    .surveyEdit{
        display:flex;
        .surveyEdit-btn{
            display: block;
            padding:5px 0 5px 20px;
            // background:#00cca2;
            margin-bottom:10px;
            border-radius:2px;
            // color:#fff;
            cursor:pointer;
            border: 1px dashed #d9d9d9;
        }
        .surveyEdit-menu{
            border-right:1px solid #e8e8e8;
            border-left:1px solid #e8e8e8;
            position: relative;
            form{
                position: absolute!important;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                overflow-y: auto;
                height: 100%;
                li{
                    padding-left: 15px;
                    padding-bottom:12px;
                    .ant-form-item{
                        margin-bottom: 0;
                    }
                }
                .menuColor{
                    background: #e6fff6;
                }
            }
            .ant-menu-vertical{
                border-right:none;
                border-top:1px solid #d9d9d9;
            }
            .ant-menu-item-selected{
                box-shadow: -3px 0px 0px #00cca2
            }
            .ant-menu-item{
                height:100%;
                position: relative;
                .surveyEdit-click{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            .undefined-item{
                height:100%;
                position: relative;
                .surveyEdit-click{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .surveyEdit-warp{
            position:relative;
            display:inline-block;
            .surveyEdit-cover{
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                span{
                    position: absolute;
                    right: 5px;
                    color: #999;
                    font-size:12px;
                }
            }
        }
        .surveyEdit-icon{
            display: inline-block;
            margin-right: 4px;
            color: #f5222d;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: '*';
        }
        .surveyEdit-right{
            width:300px;
            padding-left:20px;
            position: relative;
            form{
                position: absolute!important;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                overflow-y: auto;
                height: 100%;
            }
            .ant-form-item{
                padding-left: 20px;
                .ant-row{
                    label{
                        display: flex;
                        align-items: center;
                        margin-bottom: 8px;
                        span{
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
</style>